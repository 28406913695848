<template>
  <HomeSpain />
</template>

<script lang="ts">
import HomeSpain from '~/core/pages/home/HomeSpain.page.vue'

definePageMeta({
  layout: 'home',
})

export default defineNuxtComponent({
  components: {
    HomeSpain,
  }
})
</script>
